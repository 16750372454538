var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":_vm.items,"large":""}})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Notificaties "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Zoeken","single-line":"","hide-details":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredNotifications,"options":_vm.tableSort,"footer-props":{ itemsPerPageOptions: [10, 20, 50, 200] }},on:{"update:options":function($event){_vm.tableSort=$event}},scopedSlots:_vm._u([{key:"item.text",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.humanizedText)+" ")]}},{key:"item.wolkId",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'wolksShow', params: { id: item.wolkId } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [(!item.originalOrganization)?_c('a',{on:{"click":function($event){return _vm.showToast()}}},[_vm._v(_vm._s(item.wolk))]):_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(item.wolk)+" ")])]}}],null,true)})]}},{key:"item.references.organizationId",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'organizationsShow', params: { id: item.references.organizationId } }}},[_vm._v(" "+_vm._s(item.organization)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.humanizedKey)+" ")]}},{key:"item.sendAt",fn:function(ref){
var item = ref.item;
return [(item.sendAt)?_c('span',[_vm._v(" "+_vm._s(_vm.getHumanizedDate(item.sendAt))+" ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }