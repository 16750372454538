<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="items"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Notificaties

            <v-spacer />

            <v-text-field
              v-model="filter"
              append-icon="mdi-magnify"
              label="Zoeken"
              single-line
              hide-details
            />

            <v-spacer />
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredNotifications"
            :options.sync="tableSort"
            :footer-props="{ itemsPerPageOptions: [10, 20, 50, 200] }"
          >
            <template #[`item.text`]="{ item }">
              {{ item.humanizedText }}
            </template>

            <template #[`item.wolkId`]="{ item }">
              <router-link
                v-slot="{ href, navigate }"
                :to="{ name: 'wolksShow', params: { id: item.wolkId } }"
                custom
              >
                <a
                  v-if="!item.originalOrganization"
                  @click="showToast()"
                >{{ item.wolk }}</a>
                <a
                  v-else
                  :href="href"
                  @click="navigate"
                >
                  {{ item.wolk }}
                </a>
              </router-link>
            </template>

            <template #[`item.references.organizationId`]="{ item }">
              <router-link
                :to="{ name: 'organizationsShow', params: { id: item.references.organizationId } }"
              >
                {{ item.organization }}
              </router-link>
            </template>

            <template #[`item.type`]="{ item }">
              {{ item.humanizedKey }}
            </template>

            <template #[`item.sendAt`]="{ item }">
              <span v-if="item.sendAt">
                {{ getHumanizedDate(item.sendAt) }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

import {
  getHumanizedDate,
} from '../helpers/humanizer';

export default {
  data() {
    return {
      filter: '',
      notifications: [],
      items: [
        {
          text: 'Wolk dashboard',
          disabled: false,
          exact: true,
          to: { name: 'home' },
        },
        {
          text: 'Notificaties',
          disabled: true,
          exact: true,
          to: { name: 'notifications' },
        },
      ],
      tableSort: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['sendAt'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
      },
    };
  },
  computed: {
    filteredNotifications() {
      if (!this.filter) {
        return this.notifications;
      }

      return this.notifications
        .filter((n) => `${n._id} ${this.$store.state.auth.type === 'admin' ? n.organization : ''} ${n.humanizedText}`.toLowerCase().includes(this.filter.toLowerCase())
          || n.wolk.includes(this.filter));
    },
    headers() {
      if (this.$store.state.auth.type === 'admin') {
        return [
          { text: 'Melding', value: 'humanizedText', sortable: false },
          { text: 'Type', value: 'humanizedKey', sortable: false },
          { text: 'Wolknr.', value: 'wolkId', sortable: false },
          { text: 'ID', value: 'customerIdentifier', sortable: false },
          { text: 'Organisatie', value: 'references.organizationId', sortable: false },
          { text: 'Verzonden', value: 'sendAt' },
        ];
      }

      return [
        { text: 'Melding', value: 'humanizedText', sortable: false },
        { text: 'Type', value: 'humanizedKey', sortable: false },
        { text: 'Wolknr.', value: 'wolkId', sortable: false },
        { text: 'ID', value: 'customerIdentifier', sortable: false },
        { text: 'Verzonden', value: 'sendAt' },
      ];
    },
  },
  beforeMount() {
    this.loadNotifications();
  },
  methods: {
    getHumanizedDate(millis) {
      return getHumanizedDate(millis);
    },
    loadNotifications() {
      const url = `${config.VUE_APP_API_BASE_URL}/notifications`;

      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
      })
        .then((response) => {
          this.notifications = response.data.notifications;
        });
    },
    showToast() {
      this.$store.commit('app/snackbarText', {
        text: 'Deze Wolk is niet meer bij jouw organisatie.',
        visible: true,
      });
      this.$store.commit('app/snackbarSuccess', false);
    },
  },

};
</script>
